<template>
  <div class="header-button"></div>
</template>

<script>
import * as A from "@/assets";
export default {
  data: function () {
    return {
      LoanButtonLeft: A.LoanButtonLeft,
    };
  },
  props: {},
  methods: {
    handleClick() {
      this.clickHandler();
    },
  },
};
</script>

<style>
.header-button {
  display: inline-block;
  padding: 0px;
  justify-content: left;
  justify-self: start;
  text-align: left;
  text-align: left;
}
</style>
